import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { iconLib } from "@app/core/const/roam-icon";
import { IRoamNavigation } from "@app/shared/interfaces/sidebar-menu.interface";
import { WordDictionary } from "@app/shared/interfaces/word-dictionary.interface";
import { AssociationService } from "@app/shared/services/association.service";
import { RoamNavigationService } from "@app/shared/services/roam-navigation.service";
import { setIconType } from "../shared/global-search.const";

@Component({
  selector: "app-global-search-module",
  templateUrl: "./global-search-module.component.html",
  styleUrls: ["./global-search-module.component.scss"],
})
export class GlobalSearchModuleComponent {
  @Input()
  public hasShowMore!: boolean;

  @Input()
  public icon!: string;

  @Input()
  public title!: string;

  @Input()
  public label!: string;

  @Input()
  public data!: any[] | null;

  @Output()
  public onCloseDialog: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onDeleteItem: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onShowMore: EventEmitter<any> = new EventEmitter<any>();

  public iconClose = iconLib.closeBlack.src;

  constructor(
    private associationService: AssociationService,
    private navigationService: RoamNavigationService,
    private router: Router
  ) {}

  public setIcon: WordDictionary = setIconType;

  public navigateTo(item: any): void {
    if (this.title === "Unit") {
      this.navigateUnit(item);
    } else if (this.title === "Owner") {
      this.navigateOwner(item);
    } else if (this.title === "Tenant") {
      this.navigateTenant(item);
    } else if (this.title === "Vendor") {
      this.navigateVendor(item);
    } else if (this.title === "Violation") {
      this.navigateViolation(item);
    } else if (this.title === "Property") {
      this.navigateProperty(item);
    }
  }

  private navigateUnit(unit: any): void {
    this.router.navigate(
      [`associations/detail/${unit.property.slug}/units/`, unit.slug],
      {
        queryParams: {
          tab: 1,
          isDetail: true,
        },
      }
    );

    const navigation: IRoamNavigation = {
      page: "associations",
      type: unit.property?.slug,
      slug: unit.slug,
      openSecondMenu: true,
    };
    this.navigationService.navigation.next(navigation);
    this.onCloseDialog.emit(unit);
  }

  private navigateOwner(prop: any): void {
    this.router.navigate(["contacts/owners/", prop.id]);
    this.onCloseDialog.emit(prop);
  }

  private navigateTenant(prop: any): void {
    this.router.navigate(["contacts/tenants/", prop.id]);
    this.onCloseDialog.emit(prop);
  }

  private navigateViolation(prop: any): void {
    this.router.navigate(["violations"]);
    this.onCloseDialog.emit(prop);
  }

  private navigateVendor(prop: any): void {
    this.router.navigate(["contacts/vendors/", prop.id]);
    this.onCloseDialog.emit(prop);
  }

  private navigateProperty(prop: any): void {
    this.router.navigate(["associations/detail/", prop.slug]);
    this.onCloseDialog.emit(prop);
  }

  public deleteList(event: Event, list: any): void {
    event.stopPropagation();
    this.onDeleteItem.emit(list);
  }
}
