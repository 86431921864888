<div class="user-action" [matMenuTriggerFor]="menu">
  <div class="align-center gap-15 c-pointer">
    <app-image-view
      [width]="'40px'"
      [height]="'40px'"
      [rounded]="true"
      [image]="userInfo.avatarUrl || sampleAvatar"
    ></app-image-view>
    <div class="name-info">
      <div class="name">
        <span>{{ userInfo.name || "N/A" }}</span>
        <app-loader-dot
          *ngIf="!userInfo?.name"
          [light]="true"
          [size]="5"
          [position]="'start'"
        ></app-loader-dot>
      </div>
      <span class="icon">
        <mat-icon class="arrow-down">expand_more</mat-icon>
      </span>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu" class="mat-user-config">
  <div mat-menu-item class="user-name-item">
    <span class="name">{{ userInfo.name || "N/A" }}</span>
    <span *ngIf="!userInfo?.name"
      ><app-loader-dot [size]="5"></app-loader-dot
    ></span>
  </div>
  <button mat-menu-item (click)="openProfileDialog()">
    <mat-icon>settings</mat-icon> Profile
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon> Logout
  </button>
</mat-menu>

<ng-template #loading> </ng-template>
