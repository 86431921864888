<div
  clickOutside
  class="global-search"
  (onClickOutside)="closeSuggestion()"
>
  <div
    class="search-input-group active"
    [class.is-focus]="isOpenSuggestion"
  >
    <div class="search-input-prefix">
      <img
        class="search-input-prefix-icon"
        src="/assets/svg/search.svg"
        alt="search"
      />
    </div>

    <input
      #keywordInput
      appDebounceInput
      matInput
      class="search-input"
      placeholder="Search Roam"
      [(ngModel)]="keyword"
      [debounceTime]="1000"
      (keyup.enter)="openResults()"
      (focus)="openSuggestion()"
      (onEvent)="searchGlobal()"
    />

    <div class="search-input-suffix">
      <img
        *ngIf="!isOpenSuggestion"
        alt="search"
        class="search-input-suffix-icon"
        src="/assets/svg/search.svg"
      />

      <button
        class="input-clear"
        type="button"
        *ngIf="keyword && keyword.length"
        (click)="inputClear()"
      >
        <img
          alt="search"
          class="search-input-suffix-icon"
          src="/assets/svg/remove-grey-icon.svg"
        />
      </button>
    </div>
  </div>

  <div
    class="suggestion-group"
    *ngIf="isOpenSuggestion && keyword && keyword.length"
  >
    <div
      class="searching-list"
      *ngIf="!isLoading"
    >
      <div
        class="mb-24"
        *ngFor="let search of result; trackBy: trackFn"
      >
        <app-global-search-module
          *ngIf="search?.type"
          [data]="search?.dataDisplay"
          [hasShowMore]="search?.data?.length > 4"
          [title]="search?.type"
          (onCloseDialog)="closeSuggestionAfterClick($event)"
          (onShowMore)="showMore(search?.type)"
        />
      </div>

      <ng-container *ngIf="!selectedEntity && !selectedCategory">
        <app-global-search-module
          *ngIf="(globalSearch.keywordHistory | async)?.length && !isLoading && !result?.length "
          [data]="globalSearch.keywordHistory | async"
          [icon]="icon.historyGrey.src"
          [title]="'recent search'"
          (onCloseDialog)="closeSuggestionAfterClick($event)"
          (onDeleteItem)="deleteHistoryList($event)"
        />
      </ng-container>

      <app-global-search-category
        *ngIf="!selectedEntity && selectedCategory"
        [category]="selectedCategory"
        (categoryChange)="selectCategory($event)"
        (entityChange)="selectEntity($event)"
      />

      <app-global-search-entity
        *ngIf="selectedEntity && selectedCategory"
        [entity]="selectedEntity"
        (entityChange)="selectEntity($event)"
      />

      <app-loader-dot
        class="pt-16"
        *ngIf="isNextStageAvailable"
        [size]="3"
      />
    </div>
  </div>
</div>