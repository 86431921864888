import { Injectable, computed, signal } from "@angular/core";
import { GroupedMenu, IPageType } from "@app/shared/interfaces/page.interface";
import { PageName, subMenuGroup, subMenus } from "@app/utils/data/menu";

@Injectable()
export class MenuStore {
	
  readonly currentPage = signal<string>('');
  readonly subMenus: any = subMenus;

  renderMenu = computed(() => {
    let result;
    const currentMenu: Partial<GroupedMenu | IPageType[]> = this.subMenus[this.currentPage() as PageName] ;
    const groupMap: Record<string, any> = {};

    for (const [key, item] of Object.entries(currentMenu)) {
      if('isGroup' in item) {
        const groupName = item.groupName;
        if (!groupMap[groupName]) {
          groupMap[groupName] = { name: groupName, menus: [] };
        }

        groupMap[groupName].menus.push({
          type: item.type,
          slug: item.slug,
          title: item.title,
        });
        result = {isGroup: true, categories: Object.values(groupMap)}
      } else {
        result = this.subMenus[this.currentPage()];
      }
    }    
      return result;
    }  
  )
}